// ensure all the needed assets are loaded
import.meta.glob(['../images/**', '../fonts/**', '../icons/**']);
import * as Sentry from '@sentry/browser';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { gsap } from 'gsap';
import EvEmitter from 'ev-emitter';
import { loadAll, unloadAll } from './utils/sections/registration';
import { initScrollLinks, scrollToHash } from './utils/scroll-to-hash';
import loadScript from './utils/load-script';
import '../vendor/statamic-ajax-forms/js/statamic-ajax-forms';
import './components/announcement';
import './components/cursor';
import './components/select';
import './components/modal-video';
import 'wildlabs-frontend-core/js/utils';
import 'wildlabs-frontend-core/js/forms/file';
import 'wildlabs-frontend-core/js/accordion';
import 'wildlabs-frontend-core/js/tabs';
import 'wildlabs-frontend-core/js/toggleClass';
import './components/modal';
import './components/wc-datepicker.min';

gsap.registerPlugin(ScrollToPlugin);

Sentry.init({
  dsn: window.SENTRY && window.SENTRY.DSN,
});

class App extends EvEmitter {
  constructor() {
    super();

    this.app = document.getElementById('main');

    this.registerSections()
      .then(() => {
        this.initAccessibility();
        this.loadSections();
        this.onPageEnter();

        setTimeout(() => {
          loadScript('/vendor/statamic-captcha/js/recaptcha.js?v4', () => {
            if (window.WL.captcha && window.WL.captcha.attachReload) {
              this.captchaInterval = window.WL.captcha.attachReload();
            }
          });
        }, 3000);
      });
  }

  onPageEnter() {
    initScrollLinks();
    scrollToHash(window.location.hash);

    document.querySelectorAll('[data-scoll-top]').forEach((el) => {
      el.addEventListener('click', (ev) => {
        ev.preventDefault();
        gsap.to(window, {
          duration: 0.3,
          scrollTo: {
            y: 0,
          },
        });
      })
    })
  }

  initAccessibility() {
    document.addEventListener('click', () => document.body.classList.remove('is-tab-pressed'));
    document.addEventListener('keyup', (e) => {
      e.keyCode === 9 && document.body.classList.add('is-tab-pressed');
    });
  }

  registerSections() {
    const sections = import.meta.glob('./sections/*.js');
    const promises = [];
    for (const path in sections) {
      promises.push(sections[path]());
    }

    return Promise.all(promises);
  }

  loadSections(container = null) {
    loadAll(container || this.app);
  }

  unloadSections(container = null) {
    unloadAll(container || this.app);
  }
}

window.WL = window.WL || {};
window.WL.app = new App();
